import {useRequestEvent} from 'nuxt/app';

export default defineNuxtRouteMiddleware((to, from) => {
    const supportedLanguages = ['en', 'ru', 'fr', 'de', 'es', 'it', 'pl'];
    const pathSegments = to.fullPath.split('/');
    const currentLocale = pathSegments[1];

    let context = useRequestEvent()?.context;
    if (currentLocale && supportedLanguages.includes(currentLocale)) {

    } else if (typeof context !== 'undefined' && context?.pageInfo?.data?.url) {
        const url = context.pageInfo.data.url;

        return navigateTo(url);
    } else {
        //todo: need to move to nitro
        // let browserLanguage = 'en';
        // return navigateTo('/'+browserLanguage+to.fullPath);

        if (process.client) {
            //works bad on client
            let browserLanguage = (navigator.language || 'en').substring(0, 2)
            if (!supportedLanguages.includes(browserLanguage)) {
                browserLanguage = 'en';
            }
            window.location.href = '/' + browserLanguage + to.fullPath;
            // return navigateTo('/' + browserLanguage + to.fullPath);
        }
    }
});
